import React from 'react';

export const Groups = React.lazy(() => import('./groups/GroupsPage'));
export const EditGroups = React.lazy(() => import('./groups/edit/EditGroupsPage'));
export const CreateGroups = React.lazy(() => import('./groups/create/CreateGroupsPage'));

export const Users = React.lazy(() => import('./users/UsersPage'));
export const Analytics = React.lazy(() => import('./analytics/AnalyticsPage'));
export const ActionLog = React.lazy(() => import('./action-list/ActionListPage'));
export const SearchFilter = React.lazy(() => import('./search-filter/SearchFilterPage'));
export const Support = React.lazy(() => import('./support/Support'));


export const Profile = React.lazy(() => import('./profile/ProfilePage'));
