import { createBrowserRouter, Navigate } from 'react-router-dom';
import React, { Suspense } from 'react';
import { Root } from '@pages/Root/Root';
import { Skeleton } from 'antd';
import { EAppRoute } from './constants';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import AuthPage from '../login/AuthPage';
import { Groups, Users, Analytics, ActionLog, SearchFilter, CreateGroups, EditGroups, Profile } from '../dashboard';

export const router = createBrowserRouter([
  {
    path: EAppRoute.Root,
    element: <Root />,
    children: [
      {
        path: '',
        element: <Navigate to={EAppRoute.Groups} replace />,
      },
      {
        path: EAppRoute.Login,
        element: <AuthPage />,
      },
      {
        path: EAppRoute.Dashboard,
        element: <Navigate to={EAppRoute.Groups} replace />,
      },
      {
        path: EAppRoute.Dashboard,
        element: <PrivateRoute />,
        children: [
          {
            path: EAppRoute.Groups,
            element: (
              <Suspense fallback={<Skeleton />}>
                <Groups />
              </Suspense>
            ),
          },
          {
            path: EAppRoute.GroupsCreate,
            element: (
              <Suspense fallback={<Skeleton />}>
                <CreateGroups />
              </Suspense>
            ),
          },
          {
            path: EAppRoute.GroupsEdit,
            element: (
              <Suspense fallback={<Skeleton />}>
                <EditGroups />
              </Suspense>
            ),
          },
          {
            path: EAppRoute.Users,
            element: (
              <Suspense fallback={<Skeleton />}>
                <Users />
              </Suspense>
            ),
          },
          {
            path: EAppRoute.Analytics,
            element: (
              <Suspense fallback={<Skeleton />}>
                <Analytics />
              </Suspense>
            ),
          },
          {
            path: EAppRoute.ActionLog,
            element: (
              <Suspense fallback={<Skeleton />}>
                <ActionLog />
              </Suspense>
            ),
          },
          {
            path: EAppRoute.Search,
            element: (
              <Suspense fallback={<Skeleton />}>
                <SearchFilter />
              </Suspense>
            ),
          },
          {
            path: EAppRoute.Profile,
            element: (
              <Suspense fallback={<Skeleton />}>
                <Profile />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
]);
